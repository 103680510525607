import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseDraggableModal',{ref:_vm.modal_name,attrs:{"name":_vm.modal_name,"max-height":"80vh","width":"70vw","fullscreen":_vm.$vuetify.breakpoint.xsOnly}},[_c(VCard,{attrs:{"loading":_vm.loading}},[_c('BaseModal',[_c(VToolbar,{staticClass:"draggable_selector",attrs:{"slot":"header","dark":"","color":"primary","dense":"","flat":""},slot:"header"},[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.reset.apply(null, arguments)}}},[_c(VIcon,[_vm._v("mdi-close")])],1),_c(VToolbarTitle,[_vm._v(_vm._s(_vm.page.title))]),_c(VSpacer),_c(VBtn,{attrs:{"icon":"","disabled":_vm.loading},on:{"click":function($event){$event.stopPropagation();return _vm.save.apply(null, arguments)}}},[_c(VIcon,[_vm._v("mdi-content-save")])],1)],1),_c('div',{attrs:{"slot":"content"},slot:"content"},[_c(VDivider),_c(VCardText,[_c(VRow,[_c(VCol,[_c(VTextField,{staticClass:"mt-5",attrs:{"label":_vm.$store.getters.translate('nl')},model:{value:(_vm.dutch_name),callback:function ($$v) {_vm.dutch_name=$$v},expression:"dutch_name"}}),_c(VTextField,{attrs:{"label":_vm.$store.getters.translate('en')},model:{value:(_vm.english_name),callback:function ($$v) {_vm.english_name=$$v},expression:"english_name"}})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }