<template>
  <BaseDraggableModal
      :name="modal_name"
      max-height="80vh"
      width="70vw"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :ref="modal_name">
    <v-card :loading="loading">
      <BaseModal>
        <v-toolbar class="draggable_selector" slot="header" dark color="primary" dense flat>
          <v-btn icon @click.stop="reset">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ page.title }}</v-toolbar-title>
          <v-spacer/>
          <v-btn icon :disabled="loading" @click.stop="save">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </v-toolbar>
        <div slot="content">
          <v-divider/>
          <v-card-text>
            <v-row>
              <v-col>
                <v-text-field
                    class="mt-5"
                    :label="$store.getters.translate('nl')"
                    v-model="dutch_name"/>
                <v-text-field
                    :label="$store.getters.translate('en')"
                    v-model="english_name"/>
              </v-col>
            </v-row>
          </v-card-text>
        </div>
      </BaseModal>
    </v-card>
  </BaseDraggableModal>
</template>

<script>
import BaseModal from "../../../components/commonComponents/BaseModal";
import BaseDraggableModal from "../../../components/commonComponents/BaseDraggableModal";
import helpFunctions from "../../../plugins/helpFunctions";

export default {
  props: ["modal_name"],
  components: {
    BaseModal,
    BaseDraggableModal,
  },
  data() {
    return {
      page: {
        title: this.$store.getters.translate("edit_question_category"),
        name: "questioncategories",
        model: "questioncategory",
      },
      record: {
        name: null,
        sort_order: 0,
      },
      english_name: null,
      dutch_name: null,
      loading: false,
    };
  },
  methods: {
    ...helpFunctions.modal_functions,
    load(id) {
      this.loading = true;
      this.$http
        .get(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + id)
        .then((response) => {
          this.record = response.data;
          this.loadTranslation(this.record.name);
          this.loading = false;
        })
        .catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
    },
    save() {
      if(!this.loading) {
        if(!this.english_name && !this.dutch_name) {
          this.$toasted.error(this.$store.getters.translate("please_fill_all_required_fields"));
        }
        else {
          this.loading = true;
          if(this.english_name) {
            let english_translation = {
              key: this.record.name,
              language: 'en',
              value: this.english_name,
            };
            this.updateTranslation(english_translation);
          }
          if(this.dutch_name) {
            let dutch_translation = {
              key: this.record.name,
              language: 'nl',
              value: this.dutch_name,
            };
            this.updateTranslation(dutch_translation);
          }
          this.loading = false;
          this.$toasted.success(this.$store.getters.translate("successfully_saved"));
          this.$store.dispatch("refreshTranslations");
          this.$emit("refresh");
          this.reset();
        }
      }
    },
    loadTranslation(key) {
      this.loading = true;
      this.$http
          .post(this.$store.getters.appUrl + "v2/translations/get-by-key", { key: key })
          .then((response) => {
            response.data.forEach((translation) => {
              if (translation.language === 'en') {
                this.english_name = translation.value;
              }
              else if (translation.language === 'nl') {
                this.dutch_name = translation.value;
              }
            });
            this.loading = false;
          })
          .catch((error) => {
            this.$toasted.error(error);
            this.loading = false;
          });
    },
    updateTranslation(translation) {
      this.$http
          .post(this.$store.getters.appUrl + "v2/translations/update-by-key", translation)
          .then(() => {})
          .catch((error) => {
            this.$toasted.error(error);
            this.loading = false;
          });
    },
    reset() {
      this.record = {
        name: null,
        sort_order: null,
      };
      this.english_name = null;
      this.dutch_name = null;
      this.closeModal(this.modal_name);
    },
  },
};
</script>
